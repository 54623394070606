import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    basketCount: 0,
    compareCount: 0,
    favoriteCount: 0,
    siteid: "s1",
    langs: {},
    lang: 0,
    admin: false,
    authorized: false,
    clearcacheurl: "",
    quizCount: 0,
    filter: {},
    selectedPaySystemId: 0,
    answers: {},
    filtersQuiz: {},
    currentCity: "Москва",
    selectedOnlinePayMethod: 'cards'
  },
  mutations: {
    langsState(state, lang) {state.langs = lang;},
    adminState(state, flag) {state.admin = flag;},
    siteid(state, siteid) {state.siteid = siteid;},
    authorizedState(state, flag) {state.authorized = flag;},
    clearcacheurlState(state, clearCache) {state.clearcacheurl = clearCache;},
    quizCountChange(state, count) { state.quizCount = count },
    addFilterToStore(state, filter) { state.filter = filter },
    changeSelectedPaySystemId(state, id) { state.selectedPaySystemId = id },
    addAnswers(state, answers) { state.answers = answers },
    addFiltersQuiz(state, filtersQuiz) { state.filtersQuiz = filtersQuiz },
    setCurrentCity(state, city) { state.currentCity = city },
    changeSelectedOnlinePayMethod(state, method) { state.selectedOnlinePayMethod = method },
  },
  actions: {},
  modules: {}
})
