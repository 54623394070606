// Миксин для работы со всеми страницами
import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history'
})

import { Slide, Carousel } from 'vue-carousel'
import Layout from '@/components/layout/layout'
import LayoutHeader from '@/components/layout/layout-header'
import PhoneMaskInput from  "vue-phone-mask-input";
export default {
  data() {
    return {}
  },
  router,
  components: {
    Slide,
    Carousel,
    PhoneMaskInput,
    Layout,
    LayoutHeader,
    'layout-product-gallery': () => import('@/components/layout/product/layout-product-gallery'),
    layoutProductPage: () => import('@/components/layout/product/layout-product-page'),
    uiBreadcrumbs: () => import('@/components/ui/ui-breadcrumbs'),
    uiSelectView: () => import('@/components/ui/ui-select-view'),
    uiLabel: () => import('@/components/ui/ui-label'),
    'icon-arrow-dropdown': () => import('@/components/icon/icon-arrow-dropdown'),
    iconSide: () => import('@/components/icon/icon-side'),
    iconHeart: () => import('@/components/icon/icon-heart'),
    'ui-switcher': () => import('@/components/ui/ui-switcher'),
    'ui-button': () => import('@/components/ui/ui-button'),
    'ui-pretty-card': () => import('@/components/ui/ui-pretty-card'),
    'ui-raiting': () => import('@/components/ui/ui-raiting'),
    'ui-help-tooltip': () => import('@/components/ui/ui-help-tooltip'),
    'icon-bag': () => import('@/components/icon/icon-bag'),
    'icon-click': () => import('@/components/icon/icon-click'),
    'icon-play': () => import('@/components/icon/icon-play'),
    'layout-footer': () => import('@/components/layout/layout-footer'),
    'layout-popup': () => import('@/components/layout/layout-popup'),
    'layout-data-popup': () => import('@/components/layout/layout-data-popup'),
    'layout-sent': () => import('@/components/layout/layout-sent'),
    'layout-sent-payonline': () => import('@/components/layout/layout-sent-payonline'),
    'layout-pagination': () => import('@/components/layout/layout-pagination'),
    'ui-in-cart-notification': () => import('@/components/ui/ui-in-cart-notification'),
    'catalog-layout': () => import('@/components/layout/category/catalog-layout'),
    'ui-tabs': () => import('@/components/ui/ui-tabs.vue'),
    'quiz-widget': () => import('@/components/quiz/widget'),
    'quiz-banner': () => import('@/components/quiz/banner'),
    'quiz-modal-banner': () => import('@/components/quiz/modal-banner'),
    'icon-sleeping': () => import('@/components/icon/icon-sleeping')
  },
  mounted() {
    setTimeout(() => {
      const allImagesWithDataSrc = document.querySelectorAll('img[data-src]')
      allImagesWithDataSrc.forEach(image => {
        image.setAttribute('src', image.getAttribute('data-src'))
      })
    }, 1000)
  }
}
